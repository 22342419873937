// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__laurels_awards{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 3rem;
}

.app__laurels_awards-card{
    flex:1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
}

.app__laurels_awards-card img{
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}


@media screen and (min-width:1900px) {
    .app__laurels_awards-card{
        min-width: 390px;
    }
}

@media screen and (min-width:450px) {
    .app__laurels_awards-card{
        min-width: 100%;
        margin: 1rem 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/container/Laurels/Laurels.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;;IAEf,gBAAgB;AACpB;;AAEA;IACI,QAAM;IACN,aAAa;IACb,2BAA2B;IAC3B,uBAAuB;IACvB,gBAAgB;;IAEhB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;;AAGA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,cAAc;IAClB;AACJ","sourcesContent":[".app__laurels_awards{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: wrap;\n\n    margin-top: 3rem;\n}\n\n.app__laurels_awards-card{\n    flex:1;\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-start;\n    min-width: 230px;\n\n    margin: 1rem;\n}\n\n.app__laurels_awards-card img{\n    width: 50px;\n    height: 50px;\n}\n\n.app__laurels_awards-card_content{\n    display: flex;\n    flex-direction: column;\n    margin-left: 1rem;\n}\n\n\n@media screen and (min-width:1900px) {\n    .app__laurels_awards-card{\n        min-width: 390px;\n    }\n}\n\n@media screen and (min-width:450px) {\n    .app__laurels_awards-card{\n        min-width: 100%;\n        margin: 1rem 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
