// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.modal__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.modal__error-content {
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.returnbutton {
    margin-top: 3rem;
    width: 40%;
    border-radius: 30px;
    background-color: var(--color-golden);
}




.app__booktable-input {
    font-size: 16px; /* Set the desired font size */
    text-align: center;
}

.app__booktable-input::placeholder {
    font-size: 2rem; /* Set the desired font size */
}


  @media screen and (max-width: 650px) {
    .app__booktable-input::placeholder {
        font-size: 1rem; /* Set the desired font size */
      }
}`, "",{"version":3,"sources":["webpack://./src/container/BookTable/BookTable.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,mBAAmB;IACnB,qCAAqC;AACzC;;;;;AAKA;IACI,eAAe,EAAE,8BAA8B;IAC/C,kBAAkB;AACtB;;AAEA;IACI,eAAe,EAAE,8BAA8B;AACnD;;;EAGE;IACE;QACI,eAAe,EAAE,8BAA8B;MACjD;AACN","sourcesContent":["\n\n.modal__error {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.modal__error-content {\n    display: flex;  \n    flex-wrap: wrap;\n    justify-content: center;\n    width: 100%;\n}\n\n.returnbutton {\n    margin-top: 3rem;\n    width: 40%;\n    border-radius: 30px;\n    background-color: var(--color-golden);\n}\n\n\n\n\n.app__booktable-input {\n    font-size: 16px; /* Set the desired font size */\n    text-align: center;\n}\n\n.app__booktable-input::placeholder {\n    font-size: 2rem; /* Set the desired font size */\n}\n\n\n  @media screen and (max-width: 650px) {\n    .app__booktable-input::placeholder {\n        font-size: 1rem; /* Set the desired font size */\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
