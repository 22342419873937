// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1 1;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.app__menuitem-price{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.app__specialMenu-menu_heading{
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58px;
    letter-spacing: 0.04em;
    color:var(--color-white);
}`, "",{"version":3,"sources":["webpack://./src/components/Menuitem/MenuItem.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;;IAEd,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,WAAW;IACX,WAAW;IACX,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,wBAAwB;AAC5B","sourcesContent":[".app__menuitem {\n    width: 100%;\n    margin: 1rem 0;\n\n    display: flex;\n    flex-direction: column;\n}\n\n.app__menuitem-head {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.app__menuitem-sub {\n    width: 100%;\n    margin-top: 0.2rem;\n}\n\n.app__menuitem-name {\n    flex: 1;\n}\n\n.app__menuitem-dash {\n    width: 90px;\n    height: 1px;\n    background: var(--color-golden);\n    margin: 0 1rem;\n}\n\n.app__menuitem-price{\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n}\n\n.app__specialMenu-menu_heading{\n    font-family: var(--font-base);\n    font-weight: 600;\n    font-size: 45px;\n    line-height: 58px;\n    letter-spacing: 0.04em;\n    color:var(--color-white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
