// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__chef-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top:5rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__chef-content_quote img{
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign{
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:first-child{
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32;
    line-height: 41px;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img{
    width: 250px;
    margin-top: 3rem;
}

@media screen and (min-width:2000px){
    .app__chef-sign img{
        width: 370px;
    }
}


@media screen and (max-width:450px){
    .app__chef-sign img{
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/container/Chef/Chef.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;;AAGA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".app__chef-content{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin-top:5rem;\n}\n\n.app__chef-content_quote {\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-end;\n}\n\n.app__chef-content_quote img{\n    width: 47px;\n    height: 40px;\n    margin: 0 1rem 1rem 0;\n}\n\n.app__chef-sign{\n    width: 100%;\n    margin-top: 3rem;\n}\n\n.app__chef-sign p:first-child{\n    font-family: var(--font-base);\n    font-weight: 400;\n    font-size: 32;\n    line-height: 41px;\n    letter-spacing: 0.04rem;\n    text-transform: capitalize;\n    color: var(--color-golden);\n}\n\n.app__chef-sign img{\n    width: 250px;\n    margin-top: 3rem;\n}\n\n@media screen and (min-width:2000px){\n    .app__chef-sign img{\n        width: 370px;\n    }\n}\n\n\n@media screen and (max-width:450px){\n    .app__chef-sign img{\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
