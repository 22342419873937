// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__header {
    background-color: var(--color-black);
}

.app__header-h1{
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img{
    width: 80%;
}

@media screen and (max-width: 430px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 80px;
    }
}`, "",{"version":3,"sources":["webpack://./src/container/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,6BAA6B;IAC7B,0BAA0B;IAC1B,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".app__header {\n    background-color: var(--color-black);\n}\n\n.app__header-h1{\n    font-family: var(--font-base);\n    color: var(--color-golden);\n    letter-spacing: 0.04rem;\n    text-transform: uppercase;\n    line-height: 117px;\n    font-size: 90px;\n}\n\n.app__header-img img{\n    width: 80%;\n}\n\n@media screen and (max-width: 430px) {\n    .app__header-h1 {\n        font-size: 50px;\n        line-height: 80px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
