// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__video{
    height: 100dvh;
    position: relative;
}

.app__video video{
    width: 100%;
    height: 100%;
    object-fit: cover;  /*overlapping parts will be cropped*/
}

.app__video-overlay{
    position: absolute;
    inset: 0; /* Equivalent to top: 0; right: 0; bottom: 0; left: 0; */
    background-color: rgba(0,0,0,0.35);
}

.app__video-overlay_circle{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/container/Intro/Intro.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB,GAAG,oCAAoC;AAC5D;;AAEA;IACI,kBAAkB;IAClB,QAAQ,EAAE,wDAAwD;IAClE,kCAAkC;AACtC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,qCAAqC;IACrC,eAAe;AACnB","sourcesContent":[".app__video{\n    height: 100dvh;\n    position: relative;\n}\n\n.app__video video{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;  /*overlapping parts will be cropped*/\n}\n\n.app__video-overlay{\n    position: absolute;\n    inset: 0; /* Equivalent to top: 0; right: 0; bottom: 0; left: 0; */\n    background-color: rgba(0,0,0,0.35);\n}\n\n.app__video-overlay_circle{\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    border: 1px solid var(--color-golden);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
