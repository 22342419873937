// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */
.app__modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(143, 138, 138, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .app__modal-content{
    position: fixed;
    background: var(--color-black);
    padding: 20px;
    width: 85dvw;
    height: 80dvh;
    text-align: center;
    z-index: 3;
    top: 16%;
    left: 7%;

    border: 2px solid var(--color-golden);
    border-radius: 0.25rem;
  }
  
  .app__modal-closeButton {
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 3;
  }

  .close_button {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;
  }



  @media screen and (max-width:900px) {

    .app__modal-content{

        height: 85dvh;
        top: 12%;

      }


  }
  `, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,8BAA8B;IAC9B,aAAa;IACb,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,QAAQ;;IAER,qCAAqC;IACrC,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,0BAA0B;IAC1B,eAAe;EACjB;;;;EAIA;;IAEE;;QAEI,aAAa;QACb,QAAQ;;MAEV;;;EAGJ","sourcesContent":["/* Modal.css */\n.app__modal-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(143, 138, 138, 0.1);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 2;\n  }\n  \n  .app__modal-content{\n    position: fixed;\n    background: var(--color-black);\n    padding: 20px;\n    width: 85dvw;\n    height: 80dvh;\n    text-align: center;\n    z-index: 3;\n    top: 16%;\n    left: 7%;\n\n    border: 2px solid var(--color-golden);\n    border-radius: 0.25rem;\n  }\n  \n  .app__modal-closeButton {\n    background: #3498db;\n    color: white;\n    border: none;\n    padding: 10px 15px;\n    border-radius: 4px;\n    cursor: pointer;\n    z-index: 3;\n  }\n\n  .close_button {\n    font-size: 27px;\n    color: var(--color-golden);\n    cursor: pointer;\n  }\n\n\n\n  @media screen and (max-width:900px) {\n\n    .app__modal-content{\n\n        height: 85dvh;\n        top: 12%;\n\n      }\n\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
